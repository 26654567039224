import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from "../../components/index.module.css"
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { Button } from '@material-ui/core';


import { connect } from 'react-redux';
import * as uiActions from '../../redux/actions/uiActions';
import * as systemActions from '../../redux/actions/systemActions';
import * as sessionActions from '../../redux/actions/sessionActions';
import Api from '../../lib/api';
import Helper from '../../lib/helper';

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
  },
  {
    text: "Examples",
    url: "https://github.com/gatsbyjs/gatsby/tree/master/examples",
    description:
      "A collection of websites ranging from very basic to complex/complete that illustrate how to accomplish specific tasks within your Gatsby sites.",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Learn how to add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
  },
]

const samplePageLinks = [
  {
    text: "Page 2",
    url: "page-2",
    badge: false,
    description:
      "A simple example of linking to another page within a Gatsby site",
  },
  { text: "TypeScript", url: "using-typescript" },
  { text: "Server Side Rendering", url: "using-ssr" },
  { text: "Deferred Static Generation", url: "using-dsg" },
]

const moreLinks = [
  { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
  {
    text: "Documentation",
    url: "https://gatsbyjs.com/docs/",
  },
  {
    text: "Starters",
    url: "https://gatsbyjs.com/starters/",
  },
  {
    text: "Showcase",
    url: "https://gatsbyjs.com/showcase/",
  },
  {
    text: "Contributing",
    url: "https://www.gatsbyjs.com/contributing/",
  },
  { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPageOrg = (props) => {
  const {t} = useTranslation('translation');
  const {changeLanguage} = useI18next();

  React.useEffect(() => {
    EnableNotification();
  }, []);

  const EnableNotification = async () => {
    // Get the service worker
    let sw = await navigator.serviceWorker.ready;
    // Try to get the push notification subscription
    let sub = await sw.pushManager.getSubscription();
    
    if(!Helper.isNotNullAndUndefined(sub)) {
      // If on existing subscription, then call the sw.pushManager.subscribe to
      // create a new subscription.
      let newSub = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.VAPIDKEY_PUBLIC
      });

      // Update the server with the new subscription.
      Api.webPushReg({app_id: process.env.APP_ID, subscription: newSub});
    }
    else {
      // Update the server with the existing subscription.
      Api.webPushReg({app_id: process.env.APP_ID, subscription: sub});
    }
  }


  return (
    <Layout>
      <Seo title={t('title')} />
      <div className={styles.textCenter}>
        <StaticImage
          src="../../images/app_logo.png"
          loading="eager"
          width={64}
          quality={95}
          formats={["auto"]}
          alt=""
          style={{ marginBottom: `var(--space-3)` }}
        />
        <h1>
          Welcome to {t('title')}
        </h1>
        <p className={styles.intro}>
          <b>Example pages:</b>{" "}
          {samplePageLinks.map((link, i) => (
            <React.Fragment key={link.url}>
              <Link to={link.url}>{link.text}</Link>
              {i !== samplePageLinks.length - 1 && <> · </>}
            </React.Fragment>
          ))}
          <br />
          Edit <code>src/pages/index.js</code> to update this page.
        </p>
      </div>

      <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-around', margin: 20}}>
        <Button variant="contained" color="secondary" onClick={() => {
          props.dispatch(
            uiActions.setLanguage('en-US')
          );
          changeLanguage('en-US');
        }} >
          English
        </Button>
        <Button variant="contained" color="secondary" onClick={() => {
          props.dispatch(
            uiActions.setLanguage('zh-CN')
          );
          changeLanguage('zh-CN');
        }} >
          Chinese
        </Button>
      </div>
      <div style={{display: "flex", flexDirection: 'row', justifyContent: 'center'}}>
        <Button variant="contained" color="primary" onClick={() => {
          // Api.authenticate({
          //   email: 'sekhuat@gmail.com',
          //   password: 'Toobad123!@#'
          // })
          // .then(result => {
          //   console.log('Auth Result: ', result);
          // })
          // .catch(e => {
          //   console.error('Auth Error: ', e);
          // })
          props.dispatch(
            sessionActions.authenticate({
              email: 'sekhuat@gmail.com',
              password: 'Toobad123!@#'
            })
          );

        }} >
          Login
        </Button>
        <Button variant="contained" color="primary" onClick={() => {
          props.dispatch(
            sessionActions.refresh()
          );
        }} >
          Refresh
        </Button>
        <Button variant='outlined' onClick={() => {
          // Api.publicGetList()
          // .then(result => {
          //   console.log('publicGetList Result: ', result);
          // })
          // .catch(e => {
          //   console.error('publicGetList Error: ', e);
          // })
        }} >
          Get Public Data
        </Button>
        <Button variant='outlined' onClick={() => {
          // Api.privateGetList()
          // .then(result => {
          //   console.log('privateGetList Result: ', result);
          // })
          // .catch(e => {
          //   console.error('privateGetList Error: ', e);
          // })
        }} >
          Get Private Data
        </Button>

        <Button variant='outlined' onClick={() => {
          props.dispatch(
            systemActions.settingGet('4b3a54ae-25c8-4e1f-9d52-e0bef2e01fb1', 'zh-CN')
          );
        }} >
          Get System Setting
        </Button>

        <Button variant='outlined' onClick={() => {
          // Api.logout()
          // .then(result => {
          //   console.log('logout Result: ', result);
          // })
          // .catch(e => {
          //   console.error('logout Error: ', e);
          // })
          props.dispatch(
            sessionActions.logout()
          );
        }} >
          Logout
        </Button>
      </div>

      

      <div id='divToPrint' style={{
        width: 350,
        height: 400,
        border: '1px solid red',
        fontSize: '16',
        writingMode: 'vertical-rl',
        textOrientation: 'mixed'
      }}>
        阿弥陀佛 Amituofo
      </div>

      <ul className={styles.list}>
        {links.map(link => (
          <li key={link.url} className={styles.listItem}>
            <a
              className={styles.listItemLink}
              href={`${link.url}${utmParameters}`}
            >
              {link.text} ↗
            </a>
            <p className={styles.listItemDescription}>{link.description}</p>
          </li>
        ))}
      </ul>
      {moreLinks.map((link, i) => (
        <React.Fragment key={link.url}>
          <a href={`${link.url}${utmParameters}`}>{link.text}</a>
          {i !== moreLinks.length - 1 && <> · </>}
        </React.Fragment>
      ))}
    </Layout>
  );
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
// export const Head = () => <Seo title="Home" />

export default connect((state) => {
  return {
    session: state.session,
    ui: state.ui.interface
  };
})(IndexPageOrg);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
